import { Link } from "react-router-dom";
import ForbiddenIcon from "../../../assets/icons/forbidden_icon.svg";


const ForbiddenScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px] p-8 mt-10 ">
      <img
        src={ForbiddenIcon}
        alt="Access Denied"
        className="w-32 h-32 mb-6 text-blue"
      />
      <div className="text-center mb-10">
        <h3 className="text-lg font-semibold text-red-600 mb-2">
          403 - Access Denied
        </h3>
        <p className="text-gray-500 max-w-sm">
          You do not have permission to access this page. Please contact the
          administrator if you believe this is a mistake.
        </p>
      </div>
      <Link
        to={-1}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Go Back
      </Link>
    </div>
  );
};

export default ForbiddenScreen;
