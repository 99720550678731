import { Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getMyRole } from "./services";
import useLocalStorage from "./hooks/LocalStorage/useLocalStorage";
import LocalStorageKeys from "./data/enums/localStorageKeys";
import { extractPkSk } from "./utils/common";

const RequireRole = ({ children, allowedRoles }) => {
  const [orgSkuuid] = useLocalStorage(
    LocalStorageKeys.ORGANIZATION_ID_KEY,
    null
  );
  const { skuuid } = extractPkSk(orgSkuuid);

  const { data: myOrgInfo } = useQuery({
    queryKey: ["MyRole", skuuid],
    queryFn: () => getMyRole(skuuid),
    staleTime: 5 * 60 * 1000,
  });

  if (!myOrgInfo || !allowedRoles.includes(myOrgInfo.role)) {
    return <Navigate to="/forbidden" replace />;
  }

  return children;
};

export default RequireRole;
