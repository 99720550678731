import React from "react";
import { useNavigate } from "react-router-dom";

const LandingHero = () => {
    const navigate = useNavigate();

    const redirectToAssitant = () => {
        navigate("/mkt/load-organization");
    };

    return (
        <section className="flex flex-col items-center justify-center px-4 py-16 md:py-24 bg-[#F5F5F5]">
            <h1 className="text-[40px] md:text-[60px] font-extrabold leading-tight tracking-wider text-center font-nunito-sans">
                The ultimate{" "}
                <span className="bg-gradient-to-r from-purple-700 via-pink-500 to-orange-500 text-transparent bg-clip-text">
                    AI Platform
                </span>{" "}
                that
                <br />
                adapts to your campaign needs.
            </h1>

            <p className="mt-6 text-[14px] md:text-[16px] font-medium leading-6 text-center text-black font-poppins max-w-[75%]">
                Effortlessly transform ideas into captivating social media posts, ad creatives, e-commerce visuals, and more.
            </p>

            <button
                className="mt-10 px-10 py-4 rounded-full font-poppins font-semibold text-white text-[15px] bg-gradient-to-r from-[#ED499B] via-[#F765A3] to-[#F7AC32] hover:scale-105 transition-transform shadow-lg shadow-pink-200/50"
                onClick={redirectToAssitant}
            >
                Start Creating your Post
            </button>

            <p className="mt-6 text-[14px] font-medium text-center text-black font-poppins">
                Elevate Your Content. Elevate Your Business.
            </p>
        </section>
    );
};

export default LandingHero;