import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const generateTemplateContent = async (caption, campaignId, postId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaign/${campaignId}/posts/${postId}/content`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(caption),
      }
    );
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(responseData.error || "Network response was not ok");
    }
    return responseData;
  } catch (error) {
    console.error("Failed to generateCaption:", error);
    throw error;
  }
};

export const getUploadPresignedUrls = async (fileNames) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/presigned-urls`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify({
        file_names: fileNames,
      }),
    });

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to getUploadPresignedUrls:", error);
    throw error;
  }
};

export const uploadFileToS3 = async (file, presignedUrl) => {
  try {
    await fetch(presignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    return true;
  } catch (error) {
    console.error("Failed to uploadFileToS3:", error);
    throw error;
  }
};

export const fetchApps = async (orgSkuuid) => {
  const session = await fetchAuthSession();
  const response = await fetch(
    API_SERVER +
      `/organization/${orgSkuuid}/social-accounts`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    }
  );

  const responseData = await response.json();
  if (!response.ok)
    throw new Error(responseData.message || "Network response was not ok");
  return responseData.filter((app) => app.active);
};
