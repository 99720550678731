import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const createRole = async (payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/roles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createRole:", error);
    throw error;
  }
};

export const getMyRole = async (organizationId) => {
    try {
      const session = await fetchAuthSession();
      const response = await fetch(
        `${API_SERVER}/organization/${organizationId}/roles`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
        }
      );
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
      return responseData;
    } catch (error) {
      console.error("Failed to getMyRole:", error);
      throw error;
    }
  };