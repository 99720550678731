class RoleType {
  static OWNER = "OWNER";
  static ADMIN = "ADMIN";
  static MEMBER = "MEMBER";

  static toReadable(type) {
    switch (type) {
      case this.OWNER:
        return "Owner";
      case this.ADMIN:
        return "Admin";
      case this.MEMBER:
        return "Member";
      default:
        return null;
    }
  }

  static getTypeFromString(typeString) {
    switch (typeString) {
      case "OWNER":
        return this.OWNER;
      case "ADMIN":
        return this.ADMIN;
      case "MEMBER":
        return this.MEMBER;
      default:
        return null;
    }
  }

  static getReadableFromString(typeString) {
    return RoleType.toReadable(RoleType.getTypeFromString(typeString));
  }

  static getRoleOptions() {
    return [this.ADMIN, this.MEMBER];
  }
}

export default RoleType;