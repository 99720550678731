import React, { useState } from "react";
import CampaignRow from "./CampaignRow";
import PostRow from "./PostRow";
import TargetRow from "./TargetRow";

function CampaignsTable({ organizationskuuid, campaigns, deleteCampaignHandler}) {
  const [expandedCampaignSk, setExpandedCampaignSk] = useState(null);
  const toggleExpandCampaign = (sk) => {
    setExpandedCampaignSk(expandedCampaignSk === sk ? null : sk);
  };

  const [expandedPostSk, setExpandedPostSk] = useState(null);
  const toggleExpandPost = (sk) => {
    setExpandedPostSk(expandedPostSk === sk ? null : sk);
  };

  const [editingRow, setIsEditingRow] = useState(null);
  const toggleEditRow = (id) => {
    setIsEditingRow(editingRow === id ? null : id);
  };

  function sumMetrics(metricsList) {
    return metricsList.reduce((acc, metrics) => ({
      user_profile_clicks: acc.user_profile_clicks + (metrics.user_profile_clicks || 0),
      reply_count: acc.reply_count + (metrics.reply_count || 0),
      impression_count: acc.impression_count + (metrics.impression_count || 0),
      retweet_count: acc.retweet_count + (metrics.retweet_count || 0),
      like_count: acc.like_count + (metrics.like_count || 0),
    }), {
      user_profile_clicks: 0,
      reply_count: 0,
      impression_count: 0,
      retweet_count: 0,
      like_count: 0,
    });
  }

  function addMetricsToPosts(posts) {
    posts.forEach(post => {
      const targetMetrics = post.target_platforms.map(
        platform => platform.metrics?.metrics || {}
      );
      post.metrics = { metrics: sumMetrics(targetMetrics) };
    });
  }

  function addMetricsToCampaigns(campaigns) {
    campaigns.forEach(campaign => {
      addMetricsToPosts(campaign.posts);
      const postMetrics = campaign.posts.map(post => post.metrics?.metrics || {});
      campaign.metrics = { metrics: sumMetrics(postMetrics) };
    });
  }

  addMetricsToCampaigns(campaigns);


  const listCampaigns = (campaignList) => {
    return campaignList.map((campaign, i) => {
      return (
        <React.Fragment key={`campaign-row-${campaign.sk}`}>
          <CampaignRow
            organizationskuuid={organizationskuuid}
            currentCampaign={campaign}
            expandedRow={expandedCampaignSk}
            toggleExpandRow={toggleExpandCampaign}
            deleteCampaignHandler={deleteCampaignHandler}
          />
          {expandedCampaignSk === campaign.sk && campaign.posts && campaign.posts.length > 0 && (
            <React.Fragment key={`campaign-row-${campaign.sk}-posts`}>
              {listPosts(campaign.posts)}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    });
  };
  const listPosts = (postsList) => {
    return postsList.map((post, i) => {
      return (
        <React.Fragment key={`post-row-${post.sk}`}>
          <PostRow
            currentPost={post}
            index={i}
            expandedRow={expandedPostSk}
            toggleExpandRow={toggleExpandPost}
          />
          {expandedPostSk === post.sk && post.target_platforms && (
            <React.Fragment key={`post-row-${post.sk}-targets`}>
              {listTargets(post.target_platforms)}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    });
  };

  const listTargets = (targetsList) => {
    return targetsList.map((target, i) => {
      return (
        <React.Fragment key={`target-row-${target.sk}`}>
          <TargetRow
            currentTarget={target}
            editingRow={editingRow}
            toggleEditRow={toggleEditRow}
          />
        </React.Fragment>
      )
    });
  };

  return (
    <table className="max-md:table-fixed overflow-auto w-full">
      <thead className="bg-yet-another-gray text-light-gray font-semibold m-4">
        <tr>
          <td className="py-3 px-4 w-60">Title</td>
          <td className="py-3 px-4 w-32">Status</td>
          <td className="py-3 px-4 w-10">Views</td>
          <td className="py-3 px-4 w-10">Reactions</td>
          <td className="py-3 px-4 w-10">Comments</td>
          <td className="py-3 px-4 w-10">Actions</td>
          <td className="py-3 px-4 w-20"></td>
        </tr>
      </thead>
      <tbody>{listCampaigns(campaigns)}</tbody>
    </table>
  );
}

export default CampaignsTable;