import React, { useEffect, useState } from "react";

import { isValidEmail } from "../../utils/validations";
import CollaboratorForm from "./CollaboratorForm";

export default function CollaboratorsForm({
    collaborators,
    setCollaborators,
    setIsValidCollaboratorsData,
}) {
    const [canAddCollaborator, setCanAddCollaborator] = useState(false)

    const validateCanAddCollaborator = () => {
        if (collaborators.length === 0) return true;
        return collaborators.every((collaborator) =>
            collaborator.name.trim() !== "" &&
            isValidEmail(collaborator.email) &&
            collaborator.role.trim() !== ""
        );
    };

    const handleCollaboratorChange = (index, field, value) => {
        const updatedCollaborators = [...collaborators];
        updatedCollaborators[index] = { ...updatedCollaborators[index], [field]: value };
        setCollaborators(updatedCollaborators);
    };

    const addCollaborator = () => {
        setCollaborators([...collaborators, { name: "", email: "", role: "" }]);
    };

    const removeCollaborator = (index) => {
        const updatedCollaborators = collaborators.filter((_, i) => i !== index);
        setCollaborators(updatedCollaborators);
    };

    useEffect(() => {
        const canAddCollaborator = validateCanAddCollaborator();
        setCanAddCollaborator(canAddCollaborator);
        setIsValidCollaboratorsData(canAddCollaborator);
    }, [collaborators])

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">Add Collaborators (Optional)</h3>
                <button
                    type="button"
                    className={
                        `px-4 py-2 text-white bg-main-blue rounded hover:bg-main-blue-dark 
                        ${!canAddCollaborator && "opacity-50 cursor-not-allowed"}`
                    }
                    onClick={addCollaborator}
                    disabled={!canAddCollaborator}
                >
                    Add Collaborator
                </button>
            </div>

            <div className="space-y-4 max-h-52 overflow-y-auto">
                {collaborators.length === 0 && (
                    <p className="text-sm text-gray-500">No collaborators added yet</p>
                )}
                {collaborators.map((collaborator, index) => (
                    <CollaboratorForm
                        key={`collaborator-form-${index}`}
                        collaborator={collaborator}
                        handleCollaboratorChange={(field, value) =>
                            handleCollaboratorChange(index, field, value)
                        }
                        handleRemoveCollaborator={() => removeCollaborator(index)}
                    />
                ))}
            </div>
        </div>
    );
}