import { createContext, useContext } from 'react'
import { useLocation, Navigate } from "react-router-dom"
import { useQuery } from '@tanstack/react-query'
import { signInWithRedirect, fetchUserAttributes, fetchAuthSession, signOut } from "aws-amplify/auth"

import SyncOrganizationStorage from './utils/sync-organization-storage';

let AuthContext = createContext()

const checkSession  = async () => {
  const session = await fetchAuthSession()
  if (session.tokens)
    return await fetchUserAttributes()

  return Promise.resolve(null)
}

function AuthProvider({ children }) {
  const signin = async () => {
    await signInWithRedirect({
      provider: "Google",
    })
  }

  const signout = async () => {
    await signOut({
      provider: "Google",
    })
  }

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: checkSession,
  })

  let value = { user, signin, signout }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

function RequireAuth({ children }) {
  let location = useLocation()
  const auth = useContext(AuthContext)

  localStorage.setItem('callback-redirect', location.pathname)
  return auth.user ?
    <>
      {children}
    </>
    :
    <Navigate to="/login" state={{ from: location }} replace />
}

function AuthCallback() {
  const locationPath = localStorage.getItem('callback-redirect')
  SyncOrganizationStorage();

  if (locationPath) {
    return <Navigate to={locationPath} />
  } else {
    return <Navigate to='/mkt/organizations' />
  }
}

export { AuthProvider, AuthContext, RequireAuth, AuthCallback }
