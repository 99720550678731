import React, { useEffect } from 'react';
import SendIcon from "../../assets/sendIcon.svg";

const AssistantMessageInput = ({
    messageInput, 
    handleChange, 
    handleKeyDown, 
    disabled, 
    handleSendClick, 
    skipQuestions, 
    setSkipQuestions,
}) => {

  useEffect(() => {
    return () => {
      setSkipQuestions(false);
    }
  }, []);

  return (
    <div className="relative pt-4">
      <div className="relative">
        <textarea
          value={messageInput}
          placeholder="Ask or write anything"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          rows="2"
          className="block w-full pr-16 pl-4 py-3 border rounded-xl sm:text-md focus:outline-none resize-y custom-textarea"
        ></textarea>
        <img
          onClick={handleSendClick}
          src={SendIcon}
          alt="Send Icon"
          className="absolute right-5 top-3 w-10 h-10 cursor-pointer mt-2"
        />
        <input 
          type="checkbox"
          className="absolute right-7 top-3 mb-2"
          checked={skipQuestions}
          onChange={() => setSkipQuestions(!skipQuestions)}
        />
      </div>
    </div>
  );
};

export default AssistantMessageInput;