import React, { useEffect, useRef, useState } from "react";
import { Modal } from "flowbite-react";
import StatusType from "../../../data/enums/statusType";
import { ReactComponent as ScheduledIcon } from "../../../assets/icons/schedule_icon.svg";
import ActionButtonsGroup from "../../common/buttons/ActionButtonsGrup";
import { formatDateWithOrdinal, truncateContent } from "../../../utils/common";
import { socialPlatformIconsMap } from "../../../utils/socialPlatformUtils";
import AddSocialAccountButton from "../../Campaigns/AddSocialAccountButton";

export default function PublishModal({
  openModal,
  setOpenModal,
  organizationSocialAccounts,
  organizationskuuid,
  postTargetPlatforms,
  handlePublish,
  handleSchedule,
  selectedTargetPlatforms,
  setSelectedTargetPlatforms,
}) {
  const ref = useRef();
  const handleClose = () => {
    setOpenModal(false);
    setSelectedTargetPlatforms([]);
  };

  const [isAllDisabled, setIsAllDisabled] = useState(false);
  const [publishDisabled, setPublishDisabled] = useState(false);
  const [scheduleDisabled, setScheduleDisabled] = useState(false);

  useEffect(() => {
    const lengthProjectSocialAccounts = organizationSocialAccounts.length;
    let count = 0;
    let targetPlatformsPublished = [];

    postTargetPlatforms.forEach((targetPlatform) => {
      if (targetPlatform.status === StatusType.PUBLISHED) {
        targetPlatformsPublished.push(targetPlatform.social_account_id);
      }
      let setTargetPlatformsPublished = new Set(targetPlatformsPublished);
      count = setTargetPlatformsPublished.size;
    });
    if (count === lengthProjectSocialAccounts) return setIsAllDisabled(true);
    else setIsAllDisabled(false);
	}, [organizationSocialAccounts, postTargetPlatforms, selectedTargetPlatforms]);

  const handleAccountSelect = (event, targetPlatform, selectAll) => {
    if (selectAll) {
      const isChecked = event.target.checked;
      if (isChecked)
        return setSelectedTargetPlatforms(
          organizationSocialAccounts
            .map((currentSocialAccount) => {
              const targetPlatform = isTargetPlatform(currentSocialAccount.app);
              if (
                targetPlatform &&
                targetPlatform.status === StatusType.PUBLISHED
              ) {
                return null;
              } else {
                return {
                  targetPlatformId: targetPlatform ? targetPlatform.sk : null,
                  socialAccountId: currentSocialAccount.app,
                };
              }
            })
            .filter((item) => item !== null)
        );
      else return setSelectedTargetPlatforms([]);
    } else {
      ref.current.checked = false;
      setSelectedTargetPlatforms((prevSelected) => {
        const foundTargetPlatform = prevSelected.find(
          (currentTargetPlatform) =>
            currentTargetPlatform.socialAccountId ===
            targetPlatform.socialAccountId
        );

        return foundTargetPlatform
          ? prevSelected.filter(
              (item) => item.socialAccountId !== targetPlatform.socialAccountId
            )
          : [...prevSelected, targetPlatform];
      });
    }
  };

  const isTargetPlatform = (accountId) => {
    const targetPlatform = postTargetPlatforms.find(
      (platform) => platform.social_account_id === accountId
    );
    if (targetPlatform) return targetPlatform;
    return false;
  };

  const targetDataMap = {
    [StatusType.PUBLISHED]: (target) => ({
      bgColor: "bg-main-blue",
      date: target.published_at,
      statusReadable: StatusType.toReadable(StatusType.PUBLISHED),
      textColor: "text-white",
    }),
    [StatusType.SCHEDULED]: (target) => ({
      bgColor: "bg-[#F3F3F4]",
      date: target.schedule_time,
      statusReadable: StatusType.toReadable(StatusType.SCHEDULED),
      textColor: "text-[#000000]",
    }),
    [StatusType.FAILED]: (target) => ({
      bgColor: "bg-red-500",
      date: target.created_at,
      statusReadable: StatusType.toReadable(StatusType.FAILED),
      textColor: "text-white",
    }),
  };

  const isSocialAccountSelected = (accountId) =>
    selectedTargetPlatforms.find(
      (target) => target.socialAccountId === accountId
    );

  const handlePublishCallback = () => {
    handlePublish();
    setSelectedTargetPlatforms([]);
  };

  useEffect(() => {
    const areSelected = selectedTargetPlatforms.length === 0;

    if (areSelected || isAllDisabled) {
      setPublishDisabled(true);
      setScheduleDisabled(true);
    } else {
      setScheduleDisabled(false);
      setPublishDisabled(false);
    }
  }, [selectedTargetPlatforms]);

  return (
    <Modal show={openModal} size="lg" onClose={handleClose}>
      <Modal.Header className="text-center">
        Select Target Platform
      </Modal.Header>
      <Modal.Body className="p-3 py-6">
        <div className="pl-9 mb-2 flex justify-between items-center flex-direction-row">
          <div>
            <input
              ref={ref}
              disabled={isAllDisabled}
              id="selectAll"
              type="checkbox"
              onChange={(e) => handleAccountSelect(e, null, true)}
              className="w-4 h-4 mr-2 text-main-blue bg-gray-100 border-gray-300 rounded focus:ring-main-blue dark:focus:ring-main-blue dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="selectAll" className="text-[14px] font-medium ml-1">
              Select all
            </label>
          </div>
        </div>
        <div className="space-y-4 overflow-y-auto max-h-[120px] pr-2">
          <div>
            {organizationSocialAccounts.map((account) => {
              const social_account_id = account.social_account_id;
              const Icon =
                socialPlatformIconsMap[social_account_id.split("#")[1]];
              const targetPlatform = isTargetPlatform(social_account_id);
              const isScheduled =
                targetPlatform &&
                targetPlatform.status === StatusType.SCHEDULED;
              const isPublished =
                targetPlatform &&
                targetPlatform.status === StatusType.PUBLISHED;
              const data =
                targetPlatform &&
                targetDataMap[targetPlatform.status](targetPlatform);

              const isSelected = isSocialAccountSelected(social_account_id);

              const statusClassName =
                `font-bold py-1 rounded-md text-xs md:text-sm py-[12px] flex gap-[6px] min-w-[120px] justify-center` +
                (targetPlatform
                  ? ` ${data.bgColor} ${data.textColor}`
                  : ` bg-[#F3F3F4] text-[#000000]`);

              const statusText = targetPlatform
                ? data?.statusReadable
                : StatusType.toReadable(StatusType.READY);

              return (
                <div key={social_account_id} className="pl-9 py-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center me-4">
                      <input
                        id={social_account_id}
                        type="checkbox"
                        value={account.account_name}
                        onChange={() => {
                          const data = {
                            targetPlatformId: targetPlatform
                              ? targetPlatform.sk
                              : null,
                            socialAccountId: social_account_id,
                          };
                          handleAccountSelect(null, data, false);
                        }}
                        checked={isSelected}
                        disabled={!isPublished ? false : !!targetPlatform}
                        className="w-4 h-4 mr-2 text-main-blue bg-gray-100 border-gray-300 rounded focus:ring-main-blue dark:focus:ring-main-blue dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <Icon className="h-5 w-5 m-2 text-gray-700" />
                      <label
                        htmlFor={social_account_id}
                        className="text-[14px] font-medium ml-1"
                      >
                        <div
                          className={`line-clamp-1 font-semibold ${
                            targetPlatform && !isScheduled && "text-gray-500"
                          }`}
                        >
                          {
                            truncateContent(account.account_name, 45)
                              .truncatedText
                          }
                        </div>
                        {targetPlatform && (
                          <div className="text-[12px] text-gray-500">
                            {formatDateWithOrdinal(data.date) ?? "-"}
                          </div>
                        )}
                      </label>
                    </div>
                    <div className={statusClassName}>
                      {isScheduled && <ScheduledIcon />}
                      {statusText}
                    </div>
                  </div>
                </div>
              );
            })}
            <br />
          </div>
          {organizationSocialAccounts.length === 0 && (
            <>
              <AddSocialAccountButton organizationskuuid={organizationskuuid}/>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Publish Now"
            cancelLabel="Schedule"
            onSave={handlePublishCallback}
            onCancel={handleSchedule}
            saveDisabled={publishDisabled}
            cancelDisabled={scheduleDisabled}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}
