import React from "react";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { extractPkSk, formatDateWithOrdinal } from "../../../utils/common";
import { ReactComponent as ArrowBack } from "../../../assets/arrow_back.svg";
import EditableSpan from "../../common/forms/EditableSpan";
import { updateCampaign } from "../../../services";
import * as NotificationService from "../../../utils/notificationService";


export default function CampaignHeader({ organizationskuuid, campaign }) {
  const queryClient = useQueryClient();

  if (!campaign || !campaign.pk || !campaign.title || !campaign.created_at) {
    console.warn("Campaign data is incomplete.");
    return null;
  }

  const { pkuuid: projectSkuuid, skuuid: campaignSkuuid } = extractPkSk(campaign.id);

  const onUpdateCampaignTitle = async (newTitle) => {
    const payload = { title: newTitle };
    const updatedCampaign = await updateCampaign(projectSkuuid, campaignSkuuid, payload);
    const currentCampaignTitle = updatedCampaign.title;
    NotificationService.notifySuccess(
      "Campaign title updated successfully"
    );
    queryClient.setQueryData(
      [`campaign-${campaignSkuuid}`],
      (oldData) => {
        return {
          ...oldData,
          title: currentCampaignTitle,
        };
      }
    );
    return currentCampaignTitle;
  };

  return (
    <div className="flex flex-row p-4 justify-between border-b-very-light-blue border-b">
      <div className="flex flex-row items-center">
        <Link to={`/mkt/organizations/${organizationskuuid}/projects/${projectSkuuid}/campaigns`}>
          <ArrowBack />
        </Link>
        <h1 className="pl-6 text-xl font-semibold">
          <EditableSpan
            initialContent={campaign.title}
            charactesLimit={25}
            onSave={onUpdateCampaignTitle}
          />
        </h1>
        <div className="w-4 h-[0px] rotate-90 border border-gray-100"></div>
        <div className="max-md:hidden text-main-gray text-sm font-medium leading-snug tracking-tight">
          {formatDateWithOrdinal(campaign.created_at)}
        </div>
      </div>
    </div>
  );
}
