import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { deleteKnowledgeBase, updateKnowledgeBase } from '../../services';
import * as NotificationService from "../../utils/notificationService";
import { parseAndValidateCommaSeparatedList } from "../../utils/common";
import ExpandableListItem from "./ExpandableListItem";
import DataFilterForm from "./DataFilterForm";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import AddDataFilterTitle from "./AddDataFilterTitle";
import DataFiltersTitle from "./DataFiltersTitle";
import { DATA_FILTERS_INFO_MESSAGE } from "./../../data/textConstants";
import DataSourceType from "../../data/enums/data_source_type_enum";
import delete_data from "../../assets/delete_data_filter.svg";
import DeleteKnowledgeBaseConfirmationModal from "./DeleteKnowledgeBaseConfirmationModal";


function KnowledgeBaseEditor() {
  const location = useLocation();
  const { organizationskuuid } = useParams();
  const kb = location.state?.kb || {};
  const [, setDsDropDownIsOpen] = useState(false);
  const [dataSourceSelected, setDataSourceSelected] = useState("Select Data Source");
  const [originalDataFilters, setOriginalDataFilters] = useState([]);
  const [originalFileNames, setOriginalFileNames] = useState([]);
  const [dataFilters, setDataFilters] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [knowledgeBaseName, setKnowledgeBaseName] = useState("");
  const [, setKnowledgeBaseId] = useState(null);
  const [authors, setAuthors] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [creatingDataFilter, setCreatingDataFilter] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [deleteKBConfirmationModalIsOpen, setDeleteKBConfirmationModalIsOpen] = useState(false);
  const [validateDFFormInputs, setValidateDFFormInputs] = useState(false);

  const navigate = useNavigate();

  const handleSelect = useCallback((item) => {
    setDataSourceSelected(item);
    setDsDropDownIsOpen(false);
  }, []);

  const handleToggleExpand = useCallback((index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const handleSave = () => {
    updateKnowledgeBaseHandler();
    navigate(`/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`);
  };

  const handleSaveDf = () => {
    const authorsList = authors ? parseAndValidateCommaSeparatedList(authors) : [];
    const keywordsList = keywords ? parseAndValidateCommaSeparatedList(keywords) : [];
    const dfNameAlreadyExists = dataFilters.some((df) => df.name === filterName);
    if (dfNameAlreadyExists) {
      NotificationService.notifyError("Data filter name already exists");
      return;
    }
    if (keywordsList.length === 0) {
      NotificationService.notifyError("Please enter at least one keyword");
      return;
    }
    setDataFilters((prevDataList) => [
      ...prevDataList,
      {
        name: filterName,
        source: dataSourceSelected,
        keywords: keywordsList,
        authors: authorsList,
        start_date: startDate,
        end_date: endDate,
      },
    ]);
    setFilterName("");
    setKeywords("");
    setAuthors("");
    setStartDate("");
    setEndDate("");
    setDataSourceSelected("Select Data Source");
    setCreatingDataFilter(false);
  };

  const handleCancelEdit = () => {
    navigate(`/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`);
  };

  const updateKnowledgeBaseHandler = useCallback(async () => {
    try {
      const skuuid = kb.sk.split("#")[1];
      const payload = {
        title: knowledgeBaseName,
        knowledge_base_data_filters: dataFilters,
      };
      await updateKnowledgeBase(organizationskuuid, skuuid, payload);
      NotificationService.notifySuccess("Knowledge base updated successfully");
    } catch (error) {
      console.error("Failed to update Knowledge base: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to update Knowledge base: ${errorMessage}`);
    }
  }, [dataFilters, knowledgeBaseName]);

  useEffect(() => {
    if (kb) {
      const customDataFilter = kb.knowledge_base_data_filters?.filter(
        (df) => DataSourceType.getTypeFromString(df.source) === DataSourceType.CUSTOM_FILES
      )[0];
      setKnowledgeBaseId(kb.id);
      setKnowledgeBaseName(kb.title);
      setDataFilters(kb.knowledge_base_data_filters || []);
      setOriginalDataFilters(kb.knowledge_base_data_filters || []);
      setOriginalFileNames(customDataFilter?.file_names || []);
    }
  }, []);

  useEffect(() => {
    const isSameKbName = knowledgeBaseName === kb.title;
    let noDataFiltersChanges = dataFilters.length === originalDataFilters.length &&
      dataFilters.every((filter, index) => {
        const originalFilter = originalDataFilters[index];
        return filter.name === originalFilter.name &&
          filter.source === originalFilter.source &&
          filter.filter_id === originalFilter.filter_id;
      });
    setSaveButtonDisabled(
      creatingDataFilter || dataFilters?.length === 0 || (isSameKbName && noDataFiltersChanges)
    );
  }, [creatingDataFilter, dataFilters, knowledgeBaseName]);

  const deleteDataFilterHandler = (index) => {
    const newDataList = dataFilters.filter((_, i) => i !== index);
    setDataFilters(newDataList || []);
    setExpandedIndex(null);
  };

  const confirmDelete = async () => {
    try {
      const skuuid = deletingId.split("#")[1];
      await deleteKnowledgeBase(organizationskuuid, skuuid);
      NotificationService.notifySuccess("Knowledge base deleted successfully");
      setDeleteKBConfirmationModalIsOpen(false);
      navigate(`/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`);
    } catch (error) {
      console.error("Failed to delete knowledge base: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to delete knowledge base: ${errorMessage}`
      );
    }
  };

  return (
    <>
      <div className="flex flex-row p-4 items-center justify-between w-full max-md:flex-col max-md:items-start">
        <h1 className="text-2xl font-bold">Knowledge Base</h1>
        <div className="flex flex-1 text-xl text-center text-sky-500 rounded-none max-md:max-w-full"></div>
      </div>
      <div className="flex flex-col flex-1 h-[calc(125vh-270px)] max-lg:h-[calc(100vh-175px)] items-center">
        <div className="flex overflow-auto flex-col px-5 pt-5 max-w-4xl border border-solid border-zinc-200">
          <div className="flex overflow-auto flex-col mt-8 w-full max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <div className="flex flex-col text-center items-center self-center w-full font-medium leading-8 text-black max-w-[860px] max-md:max-w-full">
                <img
                  src={delete_data}
                  alt="Delete knowlede base"
                  className="cursor-pointer ml-auto w-6 h-6"
                  onClick={() => {
                    setDeletingId(kb.id);
                    setDeleteKBConfirmationModalIsOpen(true);
                  }}
                />
                <div className="gap-2.5 text-center self-stretch py-3 w-full text-2xl bg-white max-md:max-w-full">
                  Edit Knowledge base
                </div>
                <div className="gap-1 text-center self-stretch py-3 w-full bg-white max-md:max-w-full">
                  {DATA_FILTERS_INFO_MESSAGE}
                </div>
              </div>
              <div className="flex overflow-auto flex-col pt-6 w-full bg-white max-md:max-w-full pr-5">
                <div className="flex justify-between text-center items-center w-full max-md:max-w-full"></div>
                <div className="flex flex-col px-5 justify-center mt-2 w-full text-xl tracking-wide leading-none text-neutral-900 max-md:max-w-full">
                  <div className="self-stretch px-3 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[40px] max-md:max-w-full">
                    <input
                      type="text"
                      className="w-full h-full border-none bg-white text-xl tracking-wide leading-none"
                      placeholder="Enter Knowledge Base name"
                      value={knowledgeBaseName}
                      onChange={(e) => setKnowledgeBaseName(e.target.value)}
                    />
                  </div>
                </div>
                <DataFiltersTitle onClickAddDataFilter={() => { setCreatingDataFilter(true); }} />
                <div className="flex flex-col w-full max-md:max-w-full mt-6 space-y-4">
                  {dataFilters.map((item, index) => (
                    <div
                      key={index}
                      className="border-b border-gray-300 rounded-b-lg"
                    >
                      <ExpandableListItem
                        index={index}
                        title={item.name}
                        isExpanded={expandedIndex === index}
                        handleToggleExpand={handleToggleExpand}
                        handleDelete={deleteDataFilterHandler}
                      />
                      {expandedIndex === index && (
                        <DataFilterForm
                          item={item}
                          selectedFiles={originalFileNames}
                          readOnly
                        />
                      )}
                    </div>
                  ))}
                  {dataFilters.length === 0 && (
                    <div className="text-red-500 mt-2">At least one data filter is required</div>
                  )}
                  {creatingDataFilter && (
                    <div className="flex flex-col bg-white h-[calc(120vh-270px)]  max-lg:h-[calc(100vh-175px)]">
                      <div className="flex flex-col flex-1 items-center w-full max-md:max-w-full overflow-auto">
                        <div className="flex flex-col flex-1 px-5 py-5 max-w-full border border-solid border-zinc-200 w-[896px]">
                          <div className="flex flex-col flex-1 mt-8 w-full max-md:max-w-full">
                            <div className="flex flex-col w-full max-md:max-w-full">
                              <div className="flex flex-col pt-6 pb-12 w-full bg-white min-h-[276px] max-md:max-w-full">
                                <div className="flex justify-between items-center w-full max-md:max-w-full">
                                  <AddDataFilterTitle />
                                </div>
                                <DataFilterForm
                                  item={{
                                    name: filterName, keywords, authors,
                                    source: dataSourceSelected,
                                    start_date: startDate,
                                    end_date: endDate,
                                  }}
                                  handleSelect={handleSelect}
                                  onChangeName={setFilterName}
                                  setKeywords={setKeywords}
                                  setAuthors={setAuthors}
                                  setStartDate={setStartDate}
                                  setEndDate={setEndDate}
                                  currentDataFilters={dataFilters}
                                  fromEdit={true}
                                  validateInputs={validateDFFormInputs}
                                  setValidateInputs={setValidateDFFormInputs}
                                />
                              </div>
                            </div>
                            <ActionButtonsGroup
                              onCancel={() => { setCreatingDataFilter(false); }}
                              onSave={handleSaveDf}
                              saveDisabled={
                                filterName.trim().length === 0
                                || (DataSourceType.getTypeFromString(dataSourceSelected) === DataSourceType.NO_SELECTED)
                                || (DataSourceType.getTypeFromString(dataSourceSelected) !== DataSourceType.CUSTOM_FILES && keywords.length === 0 && authors.length === 0)
                              }
                              saveLabel="Save data filter"
                              onSaveDisabled={() => { setValidateDFFormInputs(true) }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-center items-center py-6">
                    <ActionButtonsGroup
                      onCancel={handleCancelEdit}
                      onSave={handleSave}
                      saveDisabled={saveButtonDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteKnowledgeBaseConfirmationModal
        showModal={deleteKBConfirmationModalIsOpen}
        setOpenModal={setDeleteKBConfirmationModalIsOpen}
        confirmDelete={confirmDelete}
      />
    </>
  );
}

export default KnowledgeBaseEditor;
