import * as dayjs from 'dayjs';
import DataSourceType from '../data/enums/data_source_type_enum';

export function callbackURL(socialNetwork) {
  return window.location.protocol + '//' + window.location.host + '/mkt/apps/' + socialNetwork.toLowerCase() + '/auth'
}


/**
 * Returns tomorrow's date in the format YYYY-MM-DD.
 * 
 * @returns {string} Tomorrow's date formatted as "YYYY-MM-DD".
 *
 * Example usage:
 * getTomorrowDate(); // "2024-10-06" (if today is October 5th, 2024)
 */
export function getTomorrowDate() {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  let year = tomorrow.getFullYear();
  let month = ('0' + (tomorrow.getMonth() + 1)).slice(-2); // Months are 0-based
  let day = ('0' + tomorrow.getDate()).slice(-2);

  return year + '-' + month + '-' + day;
};



export function formatDateString(dateStr) {
  return dayjs(dateStr).format('DD/MM/YYYY hh:mm A');
}

export function formatDateToYYYYMMDD(dateStr) {
  const parsedDate = dayjs(dateStr, "YYYY/MM/DD HH:mm");
  return parsedDate.isValid() ? parsedDate.toISOString() : null;
}

export function getDateAndTimeList(dateStr) {
  if (!dateStr) {
    const currentDate = dayjs();
    const formattedDate = currentDate.isValid() ? currentDate.format('YYYY-MM-DD') : "";
    const formattedTime = currentDate.isValid() ? currentDate.format('HH:mm') : "";
  
    return [formattedDate, formattedTime];
  }
  const date = dayjs(dateStr);
  const formattedDate = date.isValid() ? date.format('YYYY-MM-DD') : "";
  const formattedTime = date.isValid() ? date.format('HH:mm') : "";

  return [formattedDate, formattedTime];
}

export function formatDateTimeUS(dateStr) {
  /**
   * Return a formatted date string in the format "November 11, 2024 at 10:31 AM".
   */
  if (!dateStr) return new Date().toLocaleString();
  const date = new Date(dateStr);
  const options = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

/**
 * 
 * 03/09/2024
 */
export function formatDateString2(dateString) {
  let result = null;
  if (!dateString) return result;
  try {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    result = `${day}/${month}/${year}`
  } catch (error) {
    console.error(`Failed to format: ${dateString}`, error);
  }

  return result;
}



export const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
];



/**
 * Returns the ordinal suffix for a given number.
 *
 * @param {number} n - The number for which the ordinal suffix is to be determined.
 * @returns {string} The ordinal suffix corresponding to the number.
 */
export function getOrdinalSuffix(n) {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};



/**
 * Formats a date string into a human-readable format with an ordinal suffix for the day.
 *
 * The function converts a date string into this formatted date: "October 5th 2023 12:34 PM"
 *
 * @param {string} dateStr - A date string that can be parsed by the `Date` constructor (e.g., "2023-10-05T12:34:00").
 * @returns {string} The formatted date in the format "Month DayWithOrdinal Year Hours:Minutes AM/PM".
 *
 * Example usage:
 * formatWithOrdinal("2023-10-05T12:34:00"); // "October 5th 2023 12:34 PM"
 */
export const formatDateWithOrdinal = (dateStr, withTime = true) => {
  const date = new Date(dateStr);

  const minutes = date.getMinutes();
  const hours = date.getHours();
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString();

  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");

  const time = `${formattedHours}:${formattedMinutes} ${period}`;
  const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

  let formattedDate = `${month} ${dayWithSuffix} ${year}`;
  formattedDate += withTime ? ` ${time}` : '';
  return formattedDate;
};



export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));



export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}



/**
 * Parses and validates a comma-separated string, returning an array of trimmed non-empty elements.
 * @param {string} inputString - Comma-separated list of items (e.g., authors, keywords).
 * @returns {Array<string>} - Array of trimmed non-empty elements.
 */
export function parseAndValidateCommaSeparatedList(inputString) {
  if (typeof inputString !== 'string') {
    return [];
  }

  const itemArray = inputString.split(',').map((item) => item.trim());

  return itemArray.filter((item) => item.length > 0);
}



export const groupReferencesBySource = (references) => {
  return references.reduce((acc, ref) => {
    const dataSourceType = DataSourceType.getTypeFromString(ref.source);
    if (!acc[dataSourceType]) {
      acc[dataSourceType] = [];
    }
    acc[dataSourceType].push(ref);
    return acc;
  }, {});
};



/**
 * Truncates a given string to the specified character limit.
 * If the content exceeds the limit, it appends "..." and indicates that the text is truncated.
 * 
 * @param {string} content - The content to be truncated. Must be a valid string.
 * @param {number} [limit=150] - The maximum number of characters to keep before truncating. Defaults to 150 if not provided or invalid.
 * @returns {Object} An object containing:
 *   - `truncatedText` (string): The truncated text or the original content if invalid.
 *   - `isTruncated` (boolean): Whether the text was truncated.
 *
 * @example
 * const result1 = truncateContent("This is a long text that needs to be truncated", 10);
 * // result1: { truncatedText: "This is a...", isTruncated: true }
 */
export const truncateContent = (content, limit = 150) => {
  if (typeof content !== 'string') {
    return {
      truncatedText: '',
      isTruncated: false,
    };
  }

  if (typeof limit !== 'number' || limit <= 0) {
    limit = 150;
  }

  if (content.length > limit) {
    return {
      truncatedText: content.slice(0, limit) + "...",
      isTruncated: true,
    };
  }

  return {
    truncatedText: content,
    isTruncated: false,
  };
};



export const processStringToArrayByCommaSplit = (str) => {
  return str ? str.split(",").map((item) => item.trim()) : [];
};


export function removeFirstCharacter(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  return str.substring(1);
}

/**
 * Copies the provided text to the clipboard and updates the state indicating the copied text.
 *
 * @description
 * - Copies `text` to the clipboard using `navigator.clipboard.writeText`.
 * - Updates the `setTextCopied` state to show the copied text for 3 seconds.
 * - Resets the state to `null` after the timeout or in case of an error.
 */
export const copyToClipboardHandler = async (text, setTextCopied) => {
  try {
    if (typeof text !== 'string' || text.length === 0) throw new Error("Cannot copy");
    await navigator.clipboard.writeText(text);
    setTextCopied(text);
    setTimeout(() => setTextCopied(null), 3000);
  } catch (err) {
    setTextCopied(null);
  }
};

export function convertDateToISO(startDate, startTime) {
  const datetimeStr = `${startDate}T${startTime}:00`;
  const datetimeObj = new Date(datetimeStr);
  return datetimeObj.toISOString();
}


/**
 * Extracts the primary key (pk) and secondary key (sk) from a given input string.
 *
 * @param {string} inputString - The input string containing keys in the format "LABEL#...&LABEL#...". LABEL# is optional
 * @returns {object|null} - An object with keys { pkLabel, pkuuid, skLabel, skuuid } if valid, or throws an error if invalid.
 */
export function extractPkSk(inputString) {
  if (typeof inputString !== "string" || !inputString.includes("&")) {
    throw new Error("Invalid input: Input must be a string containing '&' to separate keys.");
  }

  const parts = inputString.split("&");

  if (parts.length !== 2) {
    throw new Error("Invalid input: Expected two parts separated by '&'.");
  }

  const [pkPart, skPart] = parts;

  const regex = /^([A-Z]*)#?([\w-]+|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

  const pkMatch = pkPart.match(regex);
  if (!pkMatch) {
    throw new Error("Invalid pk format: Expected format 'LABEL#ID' or 'ID'.");
  }
  const [, pkLabel, pkuuid] = pkMatch;

  const skMatch = skPart.match(regex);
  if (!skMatch) {
    throw new Error("Invalid sk format: Expected format 'LABEL#ID'.");
  }
  const [, skLabel, skuuid] = skMatch;

  return {
    pkLabel,
    pkuuid,
    skLabel,
    skuuid,
  };
}

export const toInt = (value) => (typeof value === "number" ? value : parseInt(value, 10));

export const formatSocialAccountStringToArray = (inputString) => {
  if (!inputString.startsWith("[") || !inputString.endsWith("]")) {
    return [];
  }

  const trimmedString = inputString.slice(1, -1).replace(/\(|\)/g, "");

  const nestedArray = trimmedString
    .split(",")
    .map((socialAccountSchedule) => {
      return socialAccountSchedule.trim().split(" ");
    });

  return nestedArray;
};


export const getInitials = (input) => {
  if (!input || input.trim() === "") {
    return "O";
  }

  const words = input.trim().split(/\s+/);
  const firstLetter = words[0]?.charAt(0).toUpperCase() || "";
  const secondLetter = words[1]?.charAt(0).toUpperCase() || "";

  return firstLetter + secondLetter;
}