
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ProjectCard from './ProjectCard';
import * as NotificationService from "../../utils/notificationService";
import { ADD_BLUE_ICON_URL } from '../../config/ExternalResources';
import {
    getMyProjects,
    deleteProject,
} from "../../services/index";
import ProjectModal from "./ProjectModal";
import EditProjectModal from "./EditProjectModal";
import DeleteConfirmationModal from '../common/modals/DeleteConfirmationModal';
import { extractPkSk } from "../../utils/common";
import LoadingCard from "../common/LoadingCard";

function ProjectsPage() {
    const queryClient = useQueryClient();
    const { organizationskuuid } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const [projectIdSelected, setProjectIdSelected] = useState(null);
    const [deletingId, setDeletingId] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const myProjectsQuery = useQuery({
        queryKey: ["myProjects", organizationskuuid],
        queryFn: async () => await getMyProjects(organizationskuuid),
        onError: (error) => {
            NotificationService.notifyError(`Failed to fetch projects: ${error.message}`);
        },
        staleTime: 60 * 1000,
    });

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const closeEditModal = () => {
        setCurrentProject(null);
        setShowEditModal(false);
    };
    const onClickOptions = (id) => {
        setProjectIdSelected((prevId) => (prevId === id ? null : id));
    };
    const onClickEditOption = (project) => {
        setCurrentProject(project);
        setShowEditModal(true);
        setProjectIdSelected(null);
    };
    const onClickDeleteOption = (project) => {
        setDeletingId(project.id);
        setIsDeleteModalOpen(true);
        setProjectIdSelected(null);
    };
    const confirmDelete = async () => {
        try {
            const { skuuid: projectSkuuid } = extractPkSk(deletingId);
            await deleteProject(organizationskuuid, projectSkuuid);
            NotificationService.notifySuccess("Project deleted successfully");
            queryClient.setQueryData(["myProjects", organizationskuuid], (oldData) => {
                if (!oldData) return [];
                return oldData.filter((project) => project.id !== deletingId);
            });
        } catch (error) {
            console.error("Failed to delete Project: ", error);
            const errorMessage = error.message || "An unexpected error occurred";
            NotificationService.notifyError(`Failed to delete Project: ${errorMessage}`);
        } finally {
            setDeletingId(null);
            setIsDeleteModalOpen(false);
        }
    };

    return (<>
        <div className="flex flex-col rounded-md shadow shadow-yet-yet-another-gray">
            <div className="flex flex-col self-stretch px-6 pt-4 pb-3 bg-white rounded-md border-b-gray-100 max-md:px-5">
                <div className="flex justify-between items-center w-full text-2xl font-bold leading-none text-black min-h-[44px] max-md:max-w-full">
                    <div className="flex-1 shrink-0 my-auto w-full min-w-[240px] max-md:max-w-full">
                        Projects
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-row rounded-md shadow p-5 shadow-yet-yet-another-gray">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
                        <div
                            onClick={openModal}
                            className="flex flex-col justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full cursor-pointer"
                        >
                            <img
                                loading="lazy"
                                src={ADD_BLUE_ICON_URL}
                                className="object-contain self-center w-12 aspect-square"
                                alt="Create prompt template"
                            />
                            <div className="mt-5 w-full text-center font-bold text-[#1DA1F2]">
                                Create Project
                            </div>
                            <div className="mt-5 w-full text-center">
                                Create a new project for <br /> organize your campaigns
                            </div>
                        </div>
                        {myProjectsQuery.isLoading ? (
                            <LoadingCard />
                        ) : myProjectsQuery.data?.length > 0 && (
                            myProjectsQuery.data.map((project) => (
                                <ProjectCard
                                    key={`ProjectCard-${project.id}`}
                                    project={project}
                                    projectIdSelected={projectIdSelected}
                                    organizationSkuuid={organizationskuuid}
                                    onClickOptions={onClickOptions}
                                    onClickEditOption={onClickEditOption}
                                    onClickDeleteOption={onClickDeleteOption}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
        {showModal && <ProjectModal showModal={showModal} closeModal={closeModal} />}
        {showEditModal && currentProject && (
            <EditProjectModal
                showModal={showEditModal}
                organizationSkuuid={organizationskuuid}
                project={currentProject}
                closeModal={closeEditModal}
            />
        )}
        <DeleteConfirmationModal
            showModal={isDeleteModalOpen}
            setOpenModal={setIsDeleteModalOpen}
            confirmDelete={confirmDelete}
            entityName="Project"
            subEntityName="campaigns"
        />
    </>);
}

export default ProjectsPage;
