import { useQuery } from '@tanstack/react-query'

import LocalStorageKeys from "../data/enums/localStorageKeys";
import { getMyOrganizations } from "../services";
import useLocalStorage from "../hooks/LocalStorage/useLocalStorage";
import { extractPkSk } from "./common";

export default function SyncOrganizationStorage() {
    const [orgSkuuid, setOrgSkuuid] = useLocalStorage(
        LocalStorageKeys.ORGANIZATION_ID_KEY,
        null
    );

    const myOrganizationsQuery = useQuery({
        queryKey: ["myOrganizations"],
        queryFn: getMyOrganizations,
        staleTime: 60 * 1000,
    });

    const organizations = myOrganizationsQuery.data || [];

    const validOrganization =
        organizations.find((org) => extractPkSk(org.id).skuuid === orgSkuuid) ||
        organizations[0];

    const skuuid  = validOrganization.id

    if (orgSkuuid !==  skuuid) {
        setOrgSkuuid(skuuid);
    }
}
