import {
    Outlet,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";


export default function ResourceLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const { organizationskuuid } = useParams();

    const baseClassName = "z-0 text-slate-500 cursor-pointer";
    const classNameSelected = "text-main-blue border-b-[3px] border-main-blue";
    const classNameUnSelected = "text-[gray]";

    const navItems = [
        {
            label: "Knowledge bases",
            route: `/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`,
            isActive: location.pathname.includes("resources/knowledge-bases"),
        },
        {
            label: "Prompt templates",
            route: `/mkt/organizations/${organizationskuuid}/resources/prompt-templates`,
            isActive: location.pathname.includes("resources/prompt-templates"),
        },
        {
            label: "Target platforms",
            route: `/mkt/organizations/${organizationskuuid}/resources/target-platforms`,
            isActive: location.pathname.includes("resources/target-platforms"),
        },
    ];

    return (
        <>
            <div className="flex relative gap-6 mt-4 border-b border-solid text-base font-semibold tracking-tight text-center">
                {navItems.map((item, index) => (
                    <div 
                        key={`resource-${index}-option`}
                        className={`${baseClassName} ${item.isActive ? classNameSelected : classNameUnSelected}`}
                        onClick={() => navigate(item.route)}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
            <br />
            <Outlet />
        </>
    );
}
