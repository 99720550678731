import React, { useState, useEffect } from "react";
import { Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import * as NotificationService from "../../utils/notificationService";
import CampaignType from "../../data/enums/campaignType";
import FileUploadForm from "./FileUploadForm";
import RadioInputGroup from "../common/forms/RadioButtonGroup";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import useCampaignContext from "../../hooks/Campaign/useCampaignContext";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";
import { validateInput } from "../../utils/validations";
import { createCampaign, createPost, fetchKnowledgeBases } from "../../services";
import { campaignOptions } from "../../config/constants";
import { extractPkSk } from "../../utils/common";
import { initalCampaign } from "../../config/constants";
import StatusType from "../../data/enums/statusType";
import DownloadCsvTemplate from "./DownloadCsvTemplate";
import LocalStorageKeys from "../../data/enums/localStorageKeys";
import useLocalStorage from "../../hooks/LocalStorage/useLocalStorage";


const CreateCampaignModal = ({ organizationskuuid, openModal, setOpenModal, refetch }) => {
  const navigate = useNavigate();
  const { csvData, csvErrors, setCsvData, setCsvErrors } = useCampaignContext();
  const initialErrors = { title: "", type: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [inputValid, setInputValid] = useState({title: true,});
  const [newCampaign, setNewCampaign] = useState(initalCampaign);
  const isCampaignFile = newCampaign.type === CampaignType.AUTOMATIC;

  const [projectIdStorageValue] = useLocalStorage(LocalStorageKeys.PROJECT_ID_KEY, null);

  const knowledgeBasesQuery = useQuery({
    queryKey: ["knowledgeBases", organizationskuuid],
    queryFn: async () => await fetchKnowledgeBases(organizationskuuid),
    staleTime: 10 * 1000,
  });

  const handleInputChange = (field, errorMessage) => (e) => {
    const value = e.target.value;
    setNewCampaign((prevState) => ({ ...prevState, [field]: value }));
    validateInput(value, field, setErrors, errorMessage);
  };

  const enableSaveButton = () => {
    const isTitleValid = newCampaign.title.trim() === "";

    if(isCampaignFile){
      return !(csvErrors.length === 0 && csvData.length > 0 && !isTitleValid)
    }

    return isTitleValid
  }

  const validateTitle = () => {
    validateInput(
      newCampaign.title,
      "title",
      setErrors,
      "Campaign title is required."
    );
  }
  
  const validateAllInputs = () => {
    validateTitle();
  };

  const createNewCampaign = async () => {
    try {
      if (!newCampaign.title) throw new Error("Campaign title is missing.");

      const campaignCreated = await createCampaign(newCampaign);
      NotificationService.notifySuccess("Campaign created successfully");

      return campaignCreated;
    } catch (error) {
      console.error("Failed to create campaign: ", error);
      throw new Error("Error occurred during campaign creation.");
    }
  };

  const createPostsFromCSV = async (projectId, campaignId) => {
    const payload = csvData.map((row) => ({
      campaign_id: `PJ#${projectId}&CP#${campaignId}`,
      title: row.title,
      ai_model: row.ai_model,
      status: StatusType.PROCESSING,
      knowledge_base_id: row.knowledge_base_id || "default",
    }));

    try {
      const posts = await Promise.all(payload.map(createPost));
      NotificationService.notifySuccess("Posts created successfully");

      return posts;
    } catch (error) {
      console.error("Failed to create posts from CSV data: ", error);
      throw new Error("Error occurred creating posts from CSV data.");
    }
  };

  const orchestrateCampaignCreation = async () => {
    try {
      const createdCampaign = await createNewCampaign();
      const { pkuuid, skuuid } = extractPkSk(createdCampaign.id);

      const isAutomatic = newCampaign.type === CampaignType.AUTOMATIC;
      if (isAutomatic) {
        await createPostsFromCSV(pkuuid, skuuid);
      }

      setOpenModal(false);
      refetch();

      navigate(`/mkt/organizations/${organizationskuuid}/projects/${pkuuid}/campaigns/${skuuid}/assistant`);
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred."
      NotificationService.notifyError(errorMessage);
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setNewCampaign(initalCampaign);
    setErrors(initialErrors);
    setCsvData([]);
    setCsvErrors([]);
  };

  useEffect(() => {
    if (openModal) {
      setNewCampaign((prevCampaign) => ({
        ...prevCampaign,
        project_id: projectIdStorageValue,
      }));
    }
  }, [openModal]);

  return (
    <Modal
      show={openModal}
      size="lg"
      onClose={handleCancel}
    >
      <Modal.Header className="text-center">Create Campaign</Modal.Header>
      <Modal.Body className="p-3 py-6">
        <div className="space-y-6">
          <div className="relative">
            <input
              className={`relative ${
                !inputValid.title
                  ? "block px-2.5 pb-2.5 pt-4 w-full h-[56px] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                  : "block px-2.5 pb-2.5 pt-4 w-full h-[56px] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
              }`}
              type="text"
              id="campaign_title"
              value={newCampaign.title}
              onChange={handleInputChange(
                "title",
                "Campaign title is required."
              )}
              onBlur={validateTitle}
              placeholder=" "
            />
            <label
              htmlFor="campaign_title"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Title *
            </label>
          </div>
          <InputErrorDisplayer message={errors.title}/>

          <div className="flex flex-col gap-4 md:flex-row md:gap-20">
            <RadioInputGroup
              name="postType"
              legend="Campaign Type"
              options={campaignOptions}
              selectedValue={newCampaign.type}
              onChange={handleInputChange("type")}
            />
          </div>
          {isCampaignFile && <DownloadCsvTemplate/>}
          {isCampaignFile && <FileUploadForm knowledgeBasesData={knowledgeBasesQuery.data}/>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Create"
            saveDisabled={enableSaveButton()}
            onSave={orchestrateCampaignCreation}
            onCancel={handleCancel}
            onSaveDisabled={validateAllInputs}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCampaignModal;
